import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_KasbonList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KASBON.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const post_KasbonSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KASBON.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_KasbonDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.KASBON.GET, {no_kasbon: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KasbonBayar = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KASBON.BAYAR, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_KasbonJurnalBack = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.KASBON.JURNAL_BACK, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_KasbonList,
  post_KasbonSave,
  get_KasbonDetail,
  post_KasbonBayar,
  post_KasbonJurnalBack
}