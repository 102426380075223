<template>
  <div
    class="modal fade"
    id="modalAddPayment"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Pembayaran Kasbon</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="setPayment">
            <div class="modal-body mt-3">
              <div class="form-group mb-2">
                <label>Cicilan Ke: &nbsp;{{ formModal.cicilan }}</label>
              </div>
              <div class="form-group">
                <label>Tanggal Pembayaran :</label>
                <Datepicker
                  :autoApply="true"
                  :closeOnScroll="true"
                  v-model="formModal.tanggal"
                  placeholder="Tanggal"
                  :maxDate="new Date()"
                  :format="format"
                  :enableTimePicker="false"
                  locale="id-ID"
                  selectText="Pilih"
                  cancelText="Batal"
                  :class="{
                    'is-invalid': formErrorModal && formErrorModal.tanggal,
                  }"
                  @update:modelValue="
                    changeDate($event, 'tanggal', 'pembayaran')
                  "
                ></Datepicker>
                <div
                  class="form-error"
                  v-if="formErrorModal && formErrorModal.tanggal"
                >
                  {{ formErrorModal.tanggal }}
                </div>
              </div>

              <div class="form-group">
                <label>Kas & Bank :</label>
                <Select2
                  v-model="formModal.akun_kas"
                  :options="OptionAccountKas"
                  placeholder="Plilih Akun Perkiraan"
                  :settings="{ templateResult: formatState, dropdownParent: '#modalAddPayment' }"
                  @change="formChange('akun_kas', 'pembayaran')"
                  @select="formChange('akun_kas', 'pembayaran')"
                  :class="{
                    'is-invalid': formErrorModal && formErrorModal.akun_kas,
                  }"
                />
                <div
                  class="form-error"
                  v-if="formErrorModal && formErrorModal.akun_kas"
                >
                  {{ formErrorModal.akun_kas }}
                </div>
              </div>

              <div class="form-group">
                <label class="">Nilai :</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    placeholder="Masukan Nilai"
                    v-model.lazy="formModal.nilai"
                    @keyup="formChange('nilai', 'pembayaran')"
                    v-money="configMoney"
                    :class="{
                      'is-invalid': formErrorModal && formErrorModal.nilai,
                    }"
                  />
                </div>
                <div
                  class="form-error"
                  v-if="formErrorModal && formErrorModal.nilai"
                >
                  {{ formErrorModal.nilai }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-save" :disabled="isPayment">
                <span class="fas fa-spinner fa-spin" v-if="isPayment"></span>
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalJurnalBack"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal Kembali</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Nomor Pembayaran</label>
              <Select2
                v-model="formJurnalKembali.id_pembayaran"
                :options="optionPembayaran"
                placeholder="Pilih akun perkiraan..."
                :settings="{dropdownParent: '#modalJurnalBack'}"
                @change="formChangeJurnal('id_pembayaran', $event)"
                @select="formChangeJurnal('id_pembayaran', $event)"
                :class="{
                  'is-invalid':
                    formErrorJurnal && formErrorJurnal.id_pembayaran,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.id_pembayaran"
              >
                {{ formErrorJurnal.id_pembayaran }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.tanggal,
                }"
                :closeOnScroll="true"
                v-model="formJurnalKembali.tanggal"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDateJurnal('', 'tanggal')"
                @update:modelValue="changeDateJurnal($event, 'tanggal')"
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formJurnalKembali.nilai"
                  :class="{
                    'is-invalid': formErrorJurnal && formErrorJurnal.nilai,
                  }"
                  @keyup="formChangeJurnal('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.nilai"
              >
                {{ formErrorJurnal.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.no_referensi,
                }"
                @keyup="formChangeJurnal('no_referensi')"
                class="form-control"
                v-model="formJurnalKembali.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.no_referensi"
              >
                {{ formErrorJurnal.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan :</label>
              <textarea
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.keterangan,
                }"
                @keyup="formChangeJurnal('keterangan')"
                class="form-control"
                v-model="formJurnalKembali.keterangan"
                placeholder="Masukkan Keterangan"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.keterangan"
              >
                {{ formErrorJurnal.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveJurnalBack"
              :disabled="
                isJurnalback ||
                formData.status_pembayaran == 'cancel' ||
                formData.status_pembayaran == 'unpaid'
              "
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isJurnalback"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Kasbon Karyawan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-kasbon"
                      >Kasbon Karyawan
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="
                validated == 1 && access && !formData.kode_project && isEditable && (formData.status != 'ditolak' && formData.status != 'tidak')
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1">
              <button class="btn btn-save mr-2" style="background: #ffa736; border-color: #ffa736" type="button" @click="accEmployeeCash" :disabled="isSubmit" v-if="formData.status == 'waiting'">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Setujui
              </button>
              <button class="btn btn-save mr-2" style="background: #ff5252; border-color: #ff5252" type="button" @click="tolakEmployeeCash" :disabled="isSubmit" v-if="formData.status == 'waiting'">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Tolak
              </button>
              <button
                :disabled="isSubmit"
                v-if="isEditable && formData.status != 'waiting'"
                class="btn btn-save"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Kasbon Karyawan</div>
                <div class="sub-title">Edit informasi kasbon karyawan</div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12" v-if="formData.kode_project">
                <div class="alert alert-info" :style="{ fontSize: '15px' }">
                  <h4 class="alert-heading" :style="{ fontSize: '17px' }">
                    Perhatian!
                  </h4>
                  <p class="mb-0">
                    Data Kasbon ini berkaitan Project dengan kode
                    <router-link
                      :to="{
                        name: 'DetailProject',
                        params: {
                          kode: formData.kode_project,
                        },
                      }"
                      >#{{ formData.kode_project }}</router-link
                    >
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Nomor :</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.no_kasbon"
                    placeholder="Masukkan Nomor"
                    disabled="true"
                  />
                </div>
              </div>

              <!-- <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Status :</label>
                  <Select2
                    v-model="formData.status"
                    :options="optionStatus"
                    placeholder="Pilih Status"
                    @change="formChange('status')"
                    @select="formChange('status')"
                    :disabled="!isEditable || validated == 1"
                    :class="{
                      'is-invalid': formError && formError.status,
                    }"
                  />
                  <div class="form-error" v-if="formError && formError.status">
                    {{ formError.status }}
                  </div>
                </div>
              </div> -->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Tanggal :</label>
                  <Datepicker
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{ 'is-invalid': formError && formError.tanggal }"
                    @update:modelValue="changeDate($event, 'tanggal')"
                    :disabled="!isEditable || validated == 1"
                  ></Datepicker>
                  <div class="form-error" v-if="formError && formError.tanggal">
                    {{ formError.tanggal }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Karyawan :</label>
                  <Select2
                    v-model="formData.id_karyawan"
                    :options="optionKaryawan"
                    placeholder="Pilih Karyawan"
                    @change="formChange('id_karyawan')"
                    @select="formChange('id_karyawan')"
                    :class="{
                      'is-invalid': formError && formError.id_karyawan,
                    }"
                    :disabled="!isEditable || validated == 1"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_karyawan"
                  >
                    {{ formError.id_karyawan }}
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Tenor :</label>
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Masukan tenor"
                      v-maska="'#############'"
                      v-model="formData.tenor"
                      :class="{ 'is-invalid': formError && formError.tenor }"
                      class="form-control text-right"
                      :disabled="!isEditable || validated == 1"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">X Cicilan</span>
                    </div>
                  </div>
                  <div class="form-error" v-if="formError && formError.tenor">
                    {{ formError.tenor }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Nilai :</label
                  >
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      id="inlineFormInputGroupUsername"
                      placeholder="Masukan Nilai Kasbon"
                      v-model.lazy="formData.nilai"
                      v-money="configMoney"
                      @keyup="formChange('nilai')"
                      :class="{ 'is-invalid': formError && formError.nilai }"
                      :disabled="!isEditable || validated == 1"
                    />
                  </div>
                  <div class="form-error" v-if="formError && formError.nilai">
                    {{ formError.nilai }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Akun Beban</label>
                  <Select2
                    v-model="formData.beban_akun"
                    :options="OptionAccountEstimated"
                    placeholder="Pilih Akun Perkiraan"
                    :settings="{ templateResult: formatState }"
                    @select="formChange('beban_akun')"
                    :class="{ 'is-invalid': formError && formError.beban_akun }"
                    :disabled="!isEditable || validated == 1"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_akun"
                  >
                    {{ formError.beban_akun }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12" v-if="!formData.kode_project">
                <div class="form-group">
                  <label>Akun Kas & Bank :</label>
                  <Select2
                    v-model="formData.akun_kas"
                    :options="OptionAccountKas"
                    placeholder="Pilih Akun Perkiraan"
                    :settings="{ templateResult: formatState }"
                    @change="formChange('akun_kas')"
                    @select="formChange('akun_kas')"
                    :class="{ 'is-invalid': formError && formError.akun_kas }"
                    :disabled="!isEditable || validated == 1"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.akun_kas"
                  >
                    {{ formError.akun_kas }}
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="">Sisa Pembayaran :</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Sisa Pembayaran"
                      v-model="sisa_pembayaran"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label>Apakah cicilan dipotong dari gaji?</label>
                  <div class="row">
                    <div class="col-12 mt-1">
                      <label>
                        <input
                          type="radio"
                          @change="formChange('payroll_cut')"
                          v-model="formData.payroll_cut"
                          :disabled="!isEditable || validated == 1"
                          value="ya"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Ya</span
                          >
                        </div>
                      </label>
                      <label class="ml-2">
                        <input
                          type="radio"
                          @change="formChange('payroll_cut')"
                          v-model="formData.payroll_cut"
                          :disabled="!isEditable || validated == 1"
                          value="tidak"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px"
                            >Tidak</span
                          >
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-12">
                <div class="form-group" v-if="formData.status == 'aktif'">
                  <label for="">Status Pembayaran</label>
                  <div>
                    <div
                      class="text-capitalize d-inline-block"
                      :class="{
                        'green-bedge': formData.status_pembayaran == 'paid',
                        'red-bedge': formData.status_pembayaran != 'paid',
                      }"
                      style="min-width: 150px"
                    >
                      {{ formData.status_pembayaran }}
                    </div>
                  </div>
                </div>
                <div class="form-group" v-else>
                  <label for="">Status</label>
                  <div>
                    <div
                      class="text-capitalize d-inline-block"
                      :class="{
                        'green-bedge': formData.status == 'aktif',
                        'red-bedge': formData.status == 'ditolak' || formData.status == 'ditolak',
                        'orange-bedge': formData.status == 'waiting',
                      }"
                      style="min-width: 150px"
                    >
                      {{ formData.status }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!formData.kode_project">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="title">History Pembayaran Kasbon</div>
                  <div class="sub-title">
                    Rincian dari Pembayaran Kasbon yang diperlukann
                  </div>
                </div>

                <div class="col-6 text-right">
                  <button
                    type="button"
                    class="btn btn-download mr-2"
                    v-show="
                      formData.status_pembayaran != 'cancel' &&
                      formData.status_pembayaran != 'unpaid'
                    "
                    @click="flipJournal"
                  >
                    Jurnal Kembali
                  </button>
                  <button
                    class="btn btn-add"
                    type="button"
                    v-if="formData.status_pembayaran != 'paid' && formData.status == 'aktif'"
                    @click="showModal"
                    :disabled="nilaiNotSame"
                  >
                    Pembayaran
                  </button>
                </div>
              </div>
              <hr />
              <div class="table-responsive mt-4">
                <table
                  class="table table-bordered table-striped"
                  id="tablePayment"
                >
                  <thead>
                    <tr>
                      <th style="width: 15%">Nomor Pembayaran</th>
                      <th style="width: 20%">Tanggal Pembayaran</th>
                      <th style="width: 20%">Cicilan Ke-</th>
                      <th style="width: 20%">Nilai</th>
                      <th style="width: 15%">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in listPembayaran" :key="index">
                      <td>{{ value.no_pembayaran }}</td>
                      <td>{{ formatDate(value.tanggal) }}</td>
                      <td>
                        {{
                          value.cicilan ? `Cicilan Ke ${value.cicilan}` : "-"
                        }}
                      </td>
                      <td class="text-right">{{ formatMoney(value.nilai) }}</td>
                      <td>
                        <div
                          class="green-bedge"
                          v-if="value.tipe == 'pembayaran'"
                        >
                          Paid
                        </div>
                        <div class="orange-bedge" v-else>Jurnal Kembali</div>
                      </td>
                    </tr>
                    <tr v-if="listPembayaran.length == 0">
                      <td colspan="5" class="text-center">Tidak ada data</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-detail-tab"
                        data-toggle="pill"
                        href="#pills-detail"
                        role="tab"
                        aria-controls="pills-detail"
                        aria-selected="true"
                        >Rincian Kasbon</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-anggota-tab"
                        data-toggle="pill"
                        href="#pills-anggota"
                        role="tab"
                        aria-controls="pills-anggota"
                        aria-selected="false"
                        >Pembayaran</a
                      >
                    </li>
                  </ul>
                </div>

                <hr style="margin-top: 0px" />
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-detail"
                    role="tabpanel"
                    aria-labelledby="pills-detail-tab"
                  >
                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped"
                        id="tableDepartment"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Akun</th>
                            <th style="width: 20%">Nama Akun</th>
                            <th style="width: 20%">Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, index) in listDetail" :key="index">
                            <td>{{ value.id }}</td>
                            <td>{{ value.nama_akun }}</td>
                            <td class="text-right">
                              {{ formatMoney(value.nilai) }}
                            </td>
                          </tr>
                          <tr v-if="listDetail.length === 0">
                            <td colspan="3" class="text-center">
                              No data available in table
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-anggota"
                    role="tabpanel"
                    aria-labelledby="pills-anggota-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <button
                          type="button"
                          class="btn btn-download mr-2"
                          v-show="
                            formData.status_pembayaran != 'cancel' &&
                            formData.status_pembayaran != 'unpaid'
                          "
                          @click="flipJournal"
                        >
                          Jurnal Kembali
                        </button>
                        <button
                          class="btn btn-add"
                          type="button"
                          v-if="formData.status_pembayaran != 'paid'"
                          @click="showModal"
                          :disabled="nilaiNotSame"
                        >
                          Pembayaran
                        </button>
                        <!-- <button class="btn btn-print-invoice" data-toggle="modal"
                                                    data-target="#modalPrintInvoice">Cetak Invoice</button> -->
                      </div>
                    </div>
                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped"
                        id="tablePayment"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Nomor Pembayaran</th>
                            <th style="width: 20%">Tanggal Pembayaran</th>
                            <th style="width: 20%">Cicilan Ke-</th>
                            <th style="width: 20%">Nilai</th>
                            <th style="width: 15%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in listPembayaran"
                            :key="index"
                          >
                            <td>{{ value.no_pembayaran }}</td>
                            <td>{{ formatDate(value.tanggal) }}</td>
                            <td>
                              {{
                                value.cicilan
                                  ? `Cicilan Ke ${value.cicilan}`
                                  : "-"
                              }}
                            </td>
                            <td class="text-right">
                              {{ formatMoney(value.nilai) }}
                            </td>
                            <td>
                              <div
                                class="green-bedge"
                                v-if="value.tipe == 'pembayaran'"
                              >
                                Paid
                              </div>
                              <div class="orange-bedge" v-else>
                                Jurnal Kembali
                              </div>
                            </td>
                          </tr>
                          <tr v-if="listPembayaran.length == 0">
                            <td colspan="5" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import { get_KaryawanList } from "../../../actions/karyawan";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../helper";

import { maska } from "maska";
import { VMoney } from "v-money";
import { get_AkunList, get_AkunDefault } from "../../../actions/akun_perkiraan";
import {
  get_KasbonDetail,
  post_KasbonSave,
  post_KasbonBayar,
  post_KasbonJurnalBack,
} from "../../../actions/kasbon";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    maska,
    money: VMoney,
  },

  data() {
    return {
      access: true,
      checkAccess: checkModuleAccess,
      validated: 1,
      optionStatus: [
        {
          id: "aktif",
          text: "Diterima",
        },
        {
          id: "tidak",
          text: "Tidak Diterima",
        },
        {
          id: "waiting",
          text: "Waiting",
        },
      ],
      OptionAccountEstimated: [],
      OptionAccountKas: [],
      optionKaryawan: [],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      isEditable: true,
      status: "tidak",
      sisaPembayaran: 0,
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      no_kasbon: this.$route.params.id,
      formData: {
        no_kasbon: this.$route.params.id,
        id_company: cksClient().get("_account").id_company,
        tanggal: "",
        id_karyawan: "",
        tenor: "",
        nilai: "",
        beban_akun: "",
        akun_kas: "",
        status: "aktif",
        status_pembayaran: "unpaid",
        no_jurnal: "",
        kode_project: "",
        payroll_cut: "tidak",
      },
      formError: [],
      rules: {
        tanggal: {
          required: true,
        },
        id_karyawan: {
          required: true,
        },
        tenor: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
        },
        beban_akun: {
          required: true,
        },
        akun_kas: {
          required: true,
        },
        payroll_cut: {
          required: true,
        }
      },
      //PEMBAYARAN
      jumlahPembayaran: 0,
      sisa_pembayaran: 0,
      nilaiNotSame: false,
      isPayment: false,
      formModal: {
        cicilan: 1,
        no_kasbon: this.$route.params.id,
        tanggal: "",
        nilai: "0",
        akun_kas: "",
        status: "aktif",
      },
      formErrorModal: [],
      rulesModal: {
        no_kasbon: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 100000000000,
        },
        akun_kas: {
          required: true,
        },
      },
      listPembayaran: [],
      dataTable: "",
      listDetail: [],

      //jurnal kembali
      isJurnalback: false,
      optionPembayaran: [],
      formJurnalKembali: {
        id_kasbon: this.$route.params.id ? this.$route.params.id : "",
        id_pembayaran: "",
        akun_kas: "",
        tanggal: "",
        nilai: "",
        no_referensi: "",
        keterangan: "",
      },
      rulesJurnalKembali: {
        id_pembayaran: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 0,
        },
        no_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
      },
      formErrorJurnal: [],
    };
  },

  mounted() {
    // this.dataTable = $("#tablePayment").DataTable({
    //   info: false,
    // });
    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  created() {
    this.defaultAkun();
    this.getAkun(1);
    this.getAkun(1, true);
    this.getKaryawan();
    if (this.no_kasbon) {
      this.getData();
    } else {
      showAlert(this.$swal, {
        title: "GAGAL!",
        msg: "Nomor kasbon tidak ditemukan",
        showCancelButton: true,
        showConfirmButton: false,
        onCancel: () => {
          this.$router.push({
            name: "DataEmployeeCash",
          });
        },
      });
    }

    if (!this.checkAccess("kasbon", "cru_kasbon")) {
      this.access = false;
    }
  },
  methods: {
    editable() {
      this.validated = 2;
    },
    defaultAkun() {
      var name_id = "akun_kasbon_karyawan";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0 && !this.formData.beban_akun) {
                this.formData.beban_akun = element;
              }
            }
          }
        }
      );
    },
    showModal() {
      this.countSisaPembayaran();
      var no = 1;
      for (const i in this.listPembayaran) {
        if (Object.hasOwnProperty.call(this.listPembayaran, i)) {
          const element = this.listPembayaran[i];
          if (element.cicilan) {
            no += 1;
          }
        }
      }
      this.formModal = {
        cicilan: no,
        tanggal: "",
        no_kasbon: this.no_kasbon,
        nilai: "0",
        akun_kas: "",
        status: "aktif",
      };
      this.rulesModal.nilai.max = this.sisa_pembayaran
        .toString()
        .split("")
        .join("");
      $("#modalAddPayment").modal("show");
    },
    hideModal() {
      $("#modalAddPayment").modal("hide");
    },
    createInvoice() {
      location.href = "/data-credit/tambah-invoice";
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    //MASTER
    formatDate(string) {
      return moment(string).format("DD MMM YYYY");
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    countSisaPembayaran() {
      var nilai = parseInt(this.formData.nilai.toString().split(".").join(""));
      for (const key in this.listPembayaran) {
        if (Object.hasOwnProperty.call(this.listPembayaran, key)) {
          const element = this.listPembayaran[key];
          nilai -= element.nilai;
        }
      }
      this.sisa_pembayaran = this.formatMoney(nilai);
    },

    getData() {
      get_KasbonDetail(
        this.no_kasbon,
        (res) => {
          var { data, history, detail } = res;
          delete data.created_at;
          delete data.updated_at;
          this.formData = data;
          this.formData.tanggal = moment(data.tanggal).format("YYYY-MM-DD");
          this.listPembayaran = history;
          this.isEditable = history.length > 0 ? false : true;
          this.status = data.status;
          this.jumlahPembayaran = data.nilai;
          this.formModal.no_kasbon = this.no_kasbon;
          this.listDetail = detail;
          this.optionPembayaran = [];
          for (const i in history) {
            if (Object.hasOwnProperty.call(history, i)) {
              const el = history[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_pembayaran,
                  text: el.no_pembayaran,
                  id_akun: el.akun_kas,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }
          this.countSisaPembayaran();
          // if (this.dataTable) {
          //   this.dataTable.destroy();
          // }
          // setTimeout(() => {
          //   this.dataTable = $("#tablePayment").DataTable({
          //     info: false,
          //   });
          // }, 500);
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataEmployeeCash",
              });
            },
          });
        }
      );
    },
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
          with_freelance: "ya"
        },
        (res) => {
          const { list } = res;
          this.optionKaryawan = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionKaryawan.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    getAkun(tipe = "", not = false) {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          not_type: not,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "1" && !not) {
            this.OptionAccountKas = data;
          } else {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    changeDate(data, key, tipe = "") {
      if (tipe == "pembayaran") {
        this.formModal[key] = moment(data).format("YYYY-MM-DD");
        this.formChange(key, tipe);
      } else {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
        this.formChange(key);
      }
    },
    async formChange(val, tipe = "") {
      if (tipe === "pembayaran") {
        if (Object.hasOwnProperty.call(this.rulesModal, val)) {
          this.rulesModal[val].changed = true;
        }
        var checka = await checkRules(this.rulesModal, this.formModal);
        this.formErrorModal = checka.error;
      } else {
        if (Object.hasOwnProperty.call(this.rules, val)) {
          this.rules[val].changed = true;
        }
        if (val === "nilai") {
          var total = parseInt(
            this.formData.nilai.toString().split(".").join("")
          );
          this.nilaiNotSame = true;
          if (this.jumlahPembayaran === total) {
            this.nilaiNotSame = false;
          }
        }
        var check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },
    //MASTER
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-4" : state.sub_level == 1 ? "pl-3" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    postData(status = '') {
      this.isSubmit = true;
      var data = this.formData;
      if (status) {
        data.status = status;
      } 
      post_KasbonSave(
        data,
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Data kasbon berhasil diperbarui",
            onSubmit: () => {
              this.$router.push({
                name: "DataEmployeeCash",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          if (key == "beban_akun" || key == "akun_kas") {
            this.rules[key].required = true; 
          }
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    async accEmployeeCash() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          if (key == "beban_akun" || key == "akun_kas") {
            this.rules[key].required = true; 
          }
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menyetujui data ini, data tidak bisa diedit kembali?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData('aktif');
          },
        });
      }
    },

    async tolakEmployeeCash() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          if (key == "beban_akun" || key == "akun_kas") {
            this.rules[key].required = false; 
          }
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan menyetujui data ini, data tidak bisa diedit kembali?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData('ditolak');
          },
        });
      }
    },

    postPayment() {
      this.isPayment = true;
      post_KasbonBayar(
        this.formModal,
        (res) => {
          this.hideModal();
          this.isPayment = false;
          this.listPembayaran = res.history_pembayaran;
          this.formData.status_pembayaran = res.status_pembayaran;
          this.status = res.status_pembayaran;
          this.isEditable = false;
          this.countSisaPembayaran();

          this.optionPembayaran = [];
          for (const i in res.history_pembayaran) {
            if (Object.hasOwnProperty.call(res.history_pembayaran, i)) {
              const el = res.history_pembayaran[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_pembayaran,
                  text: el.no_pembayaran,
                  id_akun: el.akun_kas,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }
          // if (this.dataTable) {
          //   this.dataTable.destroy();
          // }
          // setTimeout(() => {
          //   this.dataTable = $("#tablePayment").DataTable({
          //     info: false,
          //   });
          // }, 500);

          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: "Data pembayaran berhasil dicatat",
          });
        },
        () => {
          this.isPayment = false;
          this.hideModal();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "GAGAL!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 200);
        }
      );
    },
    async setPayment() {
      for (const key in this.rulesModal) {
        if (Object.hasOwnProperty.call(this.rulesModal, key)) {
          this.rulesModal[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesModal, this.formModal);
      this.formErrorModal = check.error;
      if (check.success && !this.isPayment) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, data pembayaran akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postPayment();
          },
        });
      }
    },

    //jurnal kembali
    async formChangeJurnal(val, value = "") {
      if (Object.hasOwnProperty.call(this.rulesJurnalKembali, val)) {
        this.rulesJurnalKembali[val].changed = true;
      }

      if (val == "id_pembayaran") {
        this.rulesJurnalKembali.nilai.max = this.formatMoney(value.nilai);
        this.formJurnalKembali.akun_kas = value.id_akun;
      }

      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
    },
    changeDateJurnal(data, key) {
      this.formJurnalKembali[key] = moment(data).format("YYYY-MM-DD");
      this.formChangeJurnal(key);
    },
    flipJournal() {
      this.formJurnalKembali.id_pembayaran = "";
      this.formJurnalKembali.tanggal = "";
      this.formJurnalKembali.nilai = "";
      this.formJurnalKembali.keterangan = "";
      this.formJurnalKembali.no_referensi = "";
      $("#modalJurnalBack").modal("show");
      // this.$router.push({
      //   name: "FlipJournalPayble",
      //   params: {
      //     label: "Hutang",
      //   },
      // });
    },
    postJurnalBack() {
      this.isJurnalback = true;
      post_KasbonJurnalBack(
        this.formJurnalKembali,
        () => {
          $("#modalJurnalBack").modal("hide");
          this.isJurnalback = false;
          this.getData();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Jurnal kembali berhasil dicatat",
            });
          }, 500);
        },
        () => {
          this.isJurnalback = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async saveJurnalBack() {
      for (const key in this.rulesJurnalKembali) {
        if (Object.hasOwnProperty.call(this.rulesJurnalKembali, key)) {
          this.rulesJurnalKembali[key].changed = true;
        }
      }
      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postJurnalBack();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 15px;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.btn-download {
  padding: 8px 12px;
}
</style>
